import React from "react";
import {
    Box,
    Container,
    Stack,
    Text,
  } from '@chakra-ui/react';
  
 
  
  const Footer = () => {
    return (
      <Box
      backgroundImage={"url(images/grid-lines-green.png)"}
      backgroundSize={"32px"}
        backgroundRepeat={"repeat"}
        w={"100%"}
        textAlign="-webkit-center"
        pb="15px">
        
        <Container
          as={Stack}
          maxW={'6xl'}
          pt={1}
       //   direction={{ base: 'column', md: 'row' }}
          spacing={1}
          color={"white"}
          fontFamily={"Museo500"}
          fontWeight={400}

          justify={{ base: 'center', md: 'space-between' }}
          align={{ base: 'center', md: 'center' }}>
          <Text fontSize="max(2vw, 24px)">Your Success is Our Business®</Text>
          <Text fontSize="max(1vw, 12px)">© Copyright 2022 Genuity Group, LLC. All Rights Reserved.</Text>
          <Text fontSize="max(1vw, 12px)">Genuity Group, LLC.  ●  5077 Fruitville Road, Suite 328  ●  Sarasota, FL 34232</Text>
          <Text fontSize="max(1vw, 12px)">P: 941.955.1800</Text>
        </Container>
      </Box>
    );
  }

  export default Footer;