import { extendTheme } from "@chakra-ui/react";

const colors = {
  primary: {
    100: "#E5FCF1",
    200: "#27EF96",
    300: "#10DE82",
    400: "#0EBE6F",
    500: "#536267",
    600: "#0A864F",
    700: "#086F42",
    800: "#075C37",
    900: "#064C2E"
  }
};
const fonts = { fonts: {
  heading: 'Museo500, sans-serif',
  body: 'Museo500, sans-serif',
  mono: 'Museo500, sans-serif',
}
};

const customTheme = extendTheme({ colors, fonts });

export default customTheme;
