import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs8() {
  return (
    <LandingLayout>
      <Hero
        title="Physician Credentialing: Automation is just the beginning"
        subtitle="A well-managed and growing Physician Hospital Organization (PHO) was operationally at capacity in managing its 500 plus members. Genuity was engaged to streamline and automate operational processes for key functions such as physician credentialing and re-credentialing, provider review and voting acceptance of insurer service reimbursement rates, regulatory compliance initiatives, physician referral and authorization of services, and many other physician hospital organization management functions."
        subtitle2="Genuity developed a highly functional, custom software platform automating operational processes, and integrating decision factors with managed physician member activities. As a result, the PHO successfully reduced credentialing from months to days, reduced operational staff requirements and has grown more than 30% without adding operational staff. The model has been praised by regulatory entities and used as a model for other organizations."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
