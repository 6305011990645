import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs10() {
  return (
    <LandingLayout>
      <Hero
        title="Service Company: A Strategy for aggressive growth and positioning for sale"
        subtitle="When investors approached Genuity for help in launching a new service company, there were three primary goals – 1) provide a quality of service exceeding all other competitors, 2) dominate the market and 3) create a sustainable company which can potentially be resold in ten years. The investors wanted Genuity’s help with every phase of the initiative from naming the entity to marketing of services and help with operational processes and technology."
        subtitle2="Genuity aligned a team of business, marketing and technology specialists to tackle the challenge. We jointly worked with the investors to develop and execute business and marketing plans which addressed everything from naming the company to branding, recruiting resources, multi-media marketing, sales strategies, web site services, team building and process infrastructure. The investors were successful in launching a successful business which dominated the market and generated approximately $8 million in service revenue over eighteen months."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
