import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Integration() {
  return (
    <LandingLayout>
      <Hero
        title="Integration with Current & Future Systems"
        subtitle="Business changes at a rapid pace and organizations are dependent upon a full range of various software applications and platforms and technology to support and drive their business. Genuity helps organizations overcome business challenges and strengthen technology capabilities. From mobile technology to server and mainframe applications, Genuity helps organizations modernize existing systems, re-energize older applications and integrate innovative technologies to better support and advance the business."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
