import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs1() {
  return (
    <LandingLayout>
      <Hero
        title="Banking Systems: New technologies aren’t always the answer"
        subtitle="When a leading U.S. Regional Bank licensed a new collection system, expectations were high that it would deliver every capability the bank envisioned. One year into the project, it was all too evident the scope, costs and risk factors had dramatically expanded. Vendor’s commitments were unfulfilled, and bank expectations diminished and resources, budgets and timeframes were increasing. The unanswered questions were how much more time, effort and costs will be required and what will actually be achieved?"
        subtitle2="Genuity assisted the bank in stepping back and reassessing their needs and viable options. Together we developed a renewed course of action to modernize the current production platform. This approach resulted in achieving greater operational benefits within shorter timeframes and at a fraction of the cost and effort. The approach also reduced the level of risk associated with new system implementations and conversions. The bank’s renewed approach saved multi-millions of dollars and created an industry-leading collection platform. In this situation, it paid to rethink the possibilities."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
