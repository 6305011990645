import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Corporate() {
  return (
    <LandingLayout>
      <Hero
        title="Corporate"
        subtitle="From helping entrepreneur investors launch new enterprises to servicing high-performing Fortune 500 companies, Genuity is recognized for its ability to “make-it-happen” and accomplish the unexpected."
        subtitle2="Genuity’s corporate service initiatives are generally confidential and mission-critical. Services include developing innovative technology prototypes for delivering new products or services, creating customized software solutions to support business operations, developing specialized applications to convert customer data resulting from a merger or acquisitions, developing software to support timely compliance with regulatory changes, and developing new technology platforms to support a new or expanding business operation."
        subtitle3="Whether it is developing new mobile, server and mainframe applications or integrating new technologies or re-innovating operations, Genuity provides meaningful solutions to complex challenges."
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
