import React from "react";
import { Box, Flex  } from "@chakra-ui/react";
import Logo from "../ui/Logo";

const CloseIcon = () => (
  <svg width="24" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
    <title>Close</title>
    <path
      fill="black"
      d="M9.00023 7.58599L13.9502 2.63599L15.3642 4.04999L10.4142 8.99999L15.3642 13.95L13.9502 15.364L9.00023 10.414L4.05023 15.364L2.63623 13.95L7.58623 8.99999L2.63623 4.04999L4.05023 2.63599L9.00023 7.58599Z"
    />
  </svg>
);

const MenuIcon = () => (
  <svg
    width="24px"
    viewBox="0 0 20 20"
    xmlns="http://www.w3.org/2000/svg"
    fill="black"
  >
    <title>Menu</title>
    <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
  </svg>
);

const Header = (props) => {
  const [show, setShow] = React.useState(false);
  const toggleMenu = () => setShow(!show);

  return (
    <Flex
      as="nav"
      position="fixed"
      zIndex={99}
      backgroundColor="#F2F6FB"
      opacity={0.9}
      align="center"
      justify="space-between"
      wrap="wrap"
      w="100%"
      h={20}
      p={4}
      color={["black", "black", "black", "black"]}
      fontFamily={"Museo500"}
      fontSize={{ base: "xl", sm: "lg", md: "xl", lg: "2xl"}}
      {...props}
    >
      <Flex align="center">
      <a href="/">
          <Logo
            w="170px"
            color={["white", "white", "primary.500", "primary.500"]}
            pl={10}
          />
       </a>
      </Flex>

      <Box
        fill="black"
        display={{ base: "block", md: "none" }}
        onClick={toggleMenu}
      >
        {show ? <CloseIcon fill="black" /> : <MenuIcon fill="black" />}
      </Box>

      <Box
        display={{ base: show ? "block" : "none", md: "block" }}
        flexBasis={{ base: "100%", md: "auto" }}
        height="100%"
      >
        <Flex
          justify={["center", "space-between", "flex-end", "flex-end"]}
          direction={["column", "row", "row", "row"]}
          pt={[2, 0, 0, 0]}
          bg={["white", "transparent", "transparent", "transparent"]}
          onClick={toggleMenu}
        >
          <a href="/#specialties">
            <Box
              p={2}
              mr={3}
              _hover={{ color: "white", bg: "#f5821f", borderRadius: "6px" }}
            >
              Specialties
            </Box>
          </a>
          <a href="/#services">
            <Box

              p={2}
              mr={3}
              _hover={{ color: "white", bg: "#f5821f", borderRadius: "6px" }}
            >
              Services{" "}
            </Box>
          </a>
          <a href="/#about">
            <Box
              p={2}
              mr={3}
              _hover={{ color: "white", bg: "#f5821f", borderRadius: "6px" }}
            >
              About{" "}
            </Box>
          </a>
          <a href="/#studies">
            <Box
              p={2}
              mr={3}
              _hover={{ color: "white", bg: "#f5821f", borderRadius: "6px" }}
            >
              Case Studies{" "}
            </Box>
          </a>
          <a href="/#contact">
            <Box
              p={2}
              mr={3}
              _hover={{ color: "white", bg: "#f5821f", borderRadius: "6px" }}
            >
              Contact{" "}
            </Box>
          </a>
        </Flex>
      </Box>
    </Flex>
  );
};

export default Header;
