import React from "react";
import { Flex } from "@chakra-ui/react";
import Header from "../sections/Header";
import Footer from "../sections/Footer";

export default function LandingLayout(props) {
  return (
    <Flex
      direction="column"
      align="center"
  //    maxW={{ xl: "1200px" }}
  //<div style={{marginTop:'2em'}} > </div>
      m="0 auto"
      {...props}
    >
      <Header />
      {props.children}
      <Footer />
    </Flex>
  );
}
