import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs3() {
  return (
    <LandingLayout>
      <Hero
        title="A B-to-B Banking Marketing Strategy: Wait, I have a thought"
        subtitle="When a national consumer loan processor engaged Genuity for web development, it was no big deal. Genuity was a known and proven resource and the entity only needed a few animation presentations to include within its existing website. The challenge, however, became evident when the processor and Genuity met to discuss the objectives, intent and targeted audience of the website. The existing site did not appear to fully aligned with the target audience and goals of the processor. There were potential benefits in stepping back and rethinking key factors of the target audience, web content and how best to present the processor’s brand and messaging via the website. Genuity and the processor jointly worked together in crafting a plan of action. We developed a profile of the target audience and key decision factors stimulating a call to action. The joint collaboration and planning enabled the processor to realign and go to market with a more results-oriented website and client-acquisition strategy."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
