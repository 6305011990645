import React, { useState } from "react";
import { useFormspark } from "@formspark/use-formspark";
import {
  Box,
  Text,
  Image,
  Flex,
  VStack,
  Link,
  Grid,
  GridItem,
  useBreakpointValue,
  FormControl,
  Input,
  Textarea,
  Button,
  useToast,
} from "@chakra-ui/react";

import "./Landing.css";

import LandingLayout from "../components/layouts/LandingLayout";

const FORMSPARK_FORM_ID = "WgfonHDw";

export default function Landing() {
  const toast = useToast();
  const [submit, submitting] = useFormspark({
    formId: FORMSPARK_FORM_ID,
  });

  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  // Send Contact form thru FormSpark

  const sendMessage = async (e) => {
    e.preventDefault();
    if(name !== "" && email!== "" && message !== "") {
      await submit({ name, company, email, phone, message });

      toast({
        title: "Message Sent.",
        description: "Thanks for contacting us.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
  
      // Reset form fields
  
      setName("");
      setCompany("");
      setEmail("");
      setPhone("");
      setMessage("");
    }
    
  };

  return (
    <LandingLayout>
      <Flex
        backgroundImage={"url(images/business-man.jpg)"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        w={"100%"}
        h={0}
        pt="37.96%"
        mt={20}
      >
        <Box
          backgroundImage={"url(images/grid-lines-green-transparent.png)"}
          backgroundSize={"13px"}
          backgroundRepeat={"repeat"}
          color={"#81bc41"}
          fontFamily={"Museo500"}
          fontWeight={400}
          lineHeight={1.2}
          ml={"5%"}
          mt="-30vw"
          w={"27vw"}
          h={"10vw"}
          fontSize="min(2vw, 2.778vh)"
        >
          <Text p={"1vw"}>
            "Finally, a business IT partner that actually understands business
            as well as IT."
          </Text>
          <Link href="#contact">
            <Box
              as="button"
              height="3.5vw"
              lineHeight="1.2"
              px="8px"
              fontSize="min(1.5vw, 2.0vh)"
              fontFamily={"Museo500"}
              fontWeight="semibold"
              bg="#f5821f"
              color="white"
              boxShadow="1px 7px 20px rgba(0, 112, 243, 0.5);"
              _hover={{ background: "#fd8204", transform: "scale(1.03)" }}
              borderRadius={8}
              mt={"7vw"}
            >
              Schedule a Free Consultation
            </Box>
          </Link>
        </Box>
      </Flex>
      <Box
        id="specialties"
        backgroundImage={"url(images/grid-lines-green.png)"}
        backgroundRepeat={"repeat"}
        backgroundSize={"32px"}
        w={"100%"}
        textAlign="-webkit-center"
        pb="15px"
      >
        <Grid
          //  pl={"6%"}
          w="90%"
          pt={5}
          templateColumns="repeat(auto-fit, minmax(140px, 1fr));"
          gap={2}
          fontFamily={"Museo500"}
          fontWeight={400}
          color={"white"}
          fontSize={useBreakpointValue({
            base: "sm",
            sm: "sm",
            md: "md",
            lg: "2xl",
            xl: "2xl",
          })}
        >
          <GridItem w="150px">
            <Link href="/healthcare" textDecoration={"none !important"}>
              <Image src="../images/icon-check-circle.png" boxSize="61px" />
              Healthcare
            </Link>
          </GridItem>
          <GridItem w="150px">
            <Link href="/education" textDecoration={"none !important"}>
              <Image src="../images/icon-check-circle.png" boxSize="61px" />
              Education
            </Link>
          </GridItem>
          <GridItem w="150px">
            <Link href="/corporate" textDecoration={"none !important"}>
              <Image src="../images/icon-check-circle.png" boxSize="61px" />
              Corporate
            </Link>
          </GridItem>
          <GridItem w="150px">
            <Link href="/government" textDecoration={"none !important"}>
              <Image src="../images/icon-check-circle.png" boxSize="61px" />
              Government
            </Link>
          </GridItem>
          <GridItem w="150px">
            <Link href="/banking" textDecoration={"none !important"}>
              <Image src="../images/icon-check-circle.png" boxSize="61px" />
              Banking
            </Link>
          </GridItem>
        </Grid>
      </Box>
      <Box
        w={"100%"}
        textAlign="-webkit-center"
        pt="30px"
        pb="20px"
        id="services"
      >
        <Grid
          //    templateRows="repeat(2, 1fr)"
          templateColumns="repeat(auto-fit, minmax(4, 1fr));"
          gap={0}
        >
          <GridItem colSpan={2} textAlign="-webkit-right">
            <Link href="/strategies">
              <Image src="../images/process-improvement-automation.png" />
            </Link>
          </GridItem>
          <GridItem colSpan={2} textAlign="center">
            <Link href="/customapp">
              <Image src="../images/application-development.png" />
            </Link>
          </GridItem>
          <GridItem colSpan={4} textAlign="-webkit-center">
            <Link href="/integration">
              <Image src="../images/systems-integrations.png" />
            </Link>
          </GridItem>
        </Grid>
      </Box>
      <Flex
        id="about"
        backgroundImage={"url(images/business-men-shaking-hands.jpg)"}
        backgroundSize={"cover"}
        backgroundRepeat={"no-repeat"}
        w={"100%"}
        h={0}
        pt="41.09%"
        mt={2}
      >
        <Box
          backgroundImage={"url(images/grid-lines-orange-transparent.png)"}
          backgroundSize={"32px"}
          backgroundRepeat={"repeat"}
          borderRadius={8}
          color={"white"}
          fontFamily={"Museo500"}
          fontWeight={400}
          lineHeight={1.4}
          ml={"38%"}
          mt="-32vw"
          w={"48vw"}
          h={"20vw"}
          fontSize="min(1.8vw, 2.778vh)"
        >
          <Text p={"1.1vw"}>
            From the largest mainframes to the tiniest mobile devices, Genuity®
            is a leader in application development, systems integration and
            business process automation. Since 2000, Genuity has earned the
            trust of Fortune 500 corporations as well as healthcare providers,
            educators, manufactures and government leaders.&nbsp;&nbsp;
            <Link href="/aboutus">Read More »</Link>
          </Text>
        </Box>
      </Flex>
      <Box
        id="studies"
        backgroundImage={"url(images/grid-lines-green.png)"}
        backgroundRepeat={"repeat"}
        backgroundSize={"32px"}
        w={"100%"}
        textAlign="-webkit-center"
        pb="35px"
        color={"white"}
        fontFamily={"Museo500"}
      >
        <Text p={"1.2vw"} fontSize="max(3vw, 20px)">
          Case Studies
        </Text>
        <Text fontSize="max(1.2vw, 12px)">
          Turning insights and vision into reality. Click to read case studies
          in various industries.
        </Text>
        <Link href="/casestudies">
          <Box
            as="button"
            height="35px"
            lineHeight="1.5"
            px="8px"
            fontSize="12pt"
            fontWeight="semibold"
            bg="#f5821f"
            color="white"
            _hover={{ background: "#fd8204", transform: "scale(1.03)" }}
            borderRadius={8}
            mt={"2vw"}
          >
            Read Case Studies
          </Box>
        </Link>
      </Box>

      <Box bg="white" borderRadius="lg" fontFamily={"Museo500"}>
        <Text id="contact" fontSize={"29px"} pt="25px" pl="20px" pr="20px">
          Your Success is Our Business® Contact Genuity® Today.
        </Text>
        <Box m={4} color="#0B0E3F">
          <VStack as="form"  onSubmit={sendMessage} minW="50%"  pl={10} pr={10} pb={10} spacing={5}>
            <FormControl id="name" isRequired>
              <Input
                name="nameInput"
                value={name || ""}
                onChange={(e) => {
                  setName(e.target.value);
                }}
                placeholder="Name *"
                size="md"
              />
            </FormControl>
            <FormControl id="company">
              <Input
                name="companyInput"
                value={company || ""}
                onChange={(e) => {
                  setCompany(e.target.value);
                }}
                placeholder="Company"
                size="md"
              />
            </FormControl>
            <FormControl id="email" isRequired>
              <Input
                name="emailInput"
                value={email || ""}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                placeholder="Email *"
                size="md"
                type="email"
              />
            </FormControl>
            <FormControl id="phone">
              <Input
                name="phoneInput"
                value={phone || ""}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
                placeholder="Phone"
                size="md"
              />
            </FormControl>
            <FormControl id="message" isRequired>
              <Textarea
                name="messageInput"
                value={message || ""}
                onChange={(e) => {
                  setMessage(e.target.value);
                }}
                placeholder="Your message here..."
              />
            </FormControl>
            <Button
              type="submit"
             // onClick={sendMessage}
              disabled={submitting}
              w="full"
              bg="#fd8204"
              color="white"
              boxShadow="1px 7px 20px rgba(0, 112, 243, 0.5);"
              _hover={{ background: "#fd8204", transform: "scale(1.03)" }}
            >
              Send Message
            </Button>
          </VStack>
        </Box>
      </Box>
    </LandingLayout>
  );
}
