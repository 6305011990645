import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Flex,
  Heading,
  Stack,
  Text,
  Divider,
} from "@chakra-ui/react";

export default function Hero({
  title,
  subtitle,
  subtitle2,
  subtitle3,
  image,
  ctaLink,
  ctaText,
  ...rest
}) {
  return (
    <Flex
      maxWidth={"1040px"}
      align="center"
      justify={{ base: "center", md: "space-around", xl: "space-between" }}
      direction={{ base: "column-reverse", md: "row" }}
      wrap="no-wrap"
      minH="40vh"
      px={8}
      mb={16}
      mt={20}
      {...rest}
    >
      <Stack
        //   spacing={4}
        //  w={{ base: "80%", md: "40%" }}
        p="2.5em 1.5em 3.5em 1.5em"
        align={["center", "center", "flex-start", "flex-start"]}
      >
        <Heading
          fontFamily={"Museo500"}
          mb=".8em"
          color="#80bd01"
          textAlign={["center", "center", "left", "left"]}
        >
          {title}
        </Heading>
        <Text
          fontSize={["1.1em","1.4em","1.4em","1.4em"]}
          color="primary.800"
          // whiteSpace={"pre"}
          fontWeight="normal"
          lineHeight={1.5}
          fontFamily={"Museo500"}
          textAlign={["center", "center", "left", "left"]}
          pb={8}
        >
          {subtitle}
          {subtitle2 ? <br /> : ""}
          {subtitle2 ? <br /> : ""}
          {subtitle2}
          {subtitle3 ? <br /> : ""}
          {subtitle3 ? <br /> : ""}
          {subtitle3}
        </Text>
        { ctaText ? <Divider /> : "" }
        { ctaText ? 
        <Heading pt={8} size="lg" fontFamily={"Museo500"} alignSelf={"center"}>
          Your Success is Our Business®
        </Heading> : "" }
        { ctaText ?
        <Box alignSelf={"center"} pt={4}>
          <a href={ctaLink}>
            <Button
              color="white"
              backgroundColor="#fd8204"
              borderRadius="8px"
              py="4"
              px="4"
              lineHeight="1"
              size="lg"
              fontFamily={"Museo500"}
            >
              {ctaText}
            </Button>
          </a>
        </Box>
        : ""}
      </Stack>
    </Flex>
  );
}

Hero.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  subtitle2: PropTypes.string,
  subtitle3: PropTypes.string,
  image: PropTypes.string,
  ctaText: PropTypes.string,
  ctaLink: PropTypes.string,
};

Hero.defaultProps = {
  title: "Tech Rep Tooling",
  subtitle:
    "This is the subheader section where you describe the basic benefits of your product",
  subtitle2:
    "This is the subheader section where you describe the basic benefits of your product",
  subtitle3:
    "This is the subheader section where you describe the basic benefits of your product",
  image: "https://source.unsplash.com/collection/404339/800x600",
  ctaText: "Create your account now",
  ctaLink: "/signup",
};
