import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs5() {
  return (
    <LandingLayout>
      <Hero
        title="Touchscreen Kiosks: Creating the “wow factor”"
        subtitle="When a world-class distributor was seeking new ways to bring value to its customers, it engaged Genuity. Together we brainstormed and collaborated to identify innovative ideas which could help the distributer’s customers increase sales and reduce operational costs. We also wanted the solution to include a WOW factor. To accomplish this challenge, Genuity developed an innovative prototype touchscreen kiosk application complete with robust graphics, animation and audio.  The application simplified the ordering of products and made the customer interaction fun and exciting."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
