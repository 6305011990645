import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs11() {
  return (
    <LandingLayout>
      <Hero
        title="Hospital Pharmacy Systems: The “oh, oh” vs. the “a ha” experience"
        subtitle="A nationally recognized hospital completed all the right steps in selecting and licensing a new pharmaceutical system. The lengthy due diligence process included meeting with vendors, comparing system functionality and technology and support services, etc. Now that contracting was in place, it was time to kickoff the implementation project. Imagine the surprise when the hospital found out their vendor of choice was being acquired by one of the vendors not chosen. Now there were a whole new series of challenges and risks. Some may describe this as the “Oh, Oh” moment of an unexpected event. Will the new vendor continue to support and upgrade the selected software? Will experienced resources supporting the selected system stay with the company, once acquired?"
        subtitle2="The hospital completed negotiations with the new vendor and engaged Genuity to facilitate project management including vendor coordination and oversight and project risk assessment. Genuity facilitated all project communication with vendor and hospital departments regarding system implementation, customization, testing, training and support. The project overcame a number of system and resource challenges along the way to result in a successful implementation and upgrade of the 800 bed hospital. Genuity helped to minimize risk, shorten the implementation effort and take the project from an “oh, oh” moment to the “a ha” experience."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
