import React from "react";

import LandingLayout from "../components/layouts/LandingLayout";

import {
  List,
  ListItem,
  Heading,
  Flex,
  Stack,
  Divider,
  Link,
} from "@chakra-ui/react";

export default function Casestudies() {
  const cases = [
    {
      title: "Banking Systems: New technologies aren’t always the answer",
    },
    {
      title:
        "Exponential Bank Growth: Reinvention can be the difference between “AWESOME” or “same-old-same-old”",
    },
    {
      title: "A B-to-B Banking Marketing Strategy: Wait, I have a thought",
    },
    {
      title:
        "Trade Show Breakthrough for a Manufacturer: Creating the power to differentiate",
    },
    {
      title: "Touchscreen Kiosks: Creating the “wow factor”",
    },
    {
      title: "Restaurant Kiosks: Enhancing the purchase experience",
    },
    {
      title: "Community Health: Making a meaningful difference",
    },
    {
      title: "Physician Credentialing: Automation is just the beginning",
    },
    {
      title: "Healthcare Marketing: Presentations to GO",
    },
    {
      title:
        "Service Company: A Strategy for aggressive growth and positioning for sale",
    },
    {
      title:
        "Hospital Pharmacy Systems: The “oh, oh” vs. the “a ha” experience",
    },
    {
      title:
        "Leveraging Physician Insights: Helping to make healthcare more affordable",
    },
  ];

  return (
    <LandingLayout>
      <Flex
        maxWidth={"1040px"}
        align="center"
        justify={{ base: "center", md: "space-around", xl: "space-between" }}
        direction={{ base: "column-reverse", md: "row" }}
        wrap="no-wrap"
        minH="40vh"
        px={8}
        mb={16}
        mt={20}
      >
        <Stack
          //   spacing={4}
          //  w={{ base: "80%", md: "40%" }}
          p="2.5em 4.5em 3.5em"
          align={["center", "center", "flex-start", "flex-start"]}
        >
          <Heading
            fontFamily={"Museo500"}
            mb="1.8em"
            color="#80bd01"
            textAlign={["center", "center", "left", "left"]}
          >
            Case Studies
          </Heading>
          <List spacing={3} fontSize="1.5em" fontFamily={"Museo500"}>
            {cases.map((card, index) => (
              <div>
                <ListItem
                  _hover={{ bg: "#fd8204", color: "white"}}
                  p={3}
                  borderRadius={"6px"}
                >
                  <Link
                    href={`/cs${index + 1}`}
                    textDecoration={"none !important"}
                  >
                    {card.title}
                  </Link>
                </ListItem>
                <Divider  pt={2} borderColor="darkorange"/>
              </div>
            ))}
          </List>
        </Stack>
      </Flex>
    </LandingLayout>
  );
}
