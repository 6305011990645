import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Landing from "./pages/Landing";
import Aboutus from "./pages/aboutus";
import Healthcare from "./pages/healthcare";
import Education from "./pages/education";
import Corporate from "./pages/corporate";
import Government from "./pages/government";
import Banking from "./pages/banking";
import Strategies from "./pages/strategies";
import Customapp from "./pages/customapp";
import Integration from "./pages/integration";
import Casestudies from "./pages/casestudies";
import Cs1 from "./pages/cs1";
import Cs2 from "./pages/cs2";
import Cs3 from "./pages/cs3";
import Cs4 from "./pages/cs4";
import Cs5 from "./pages/cs5";
import Cs6 from "./pages/cs6";
import Cs7 from "./pages/cs7";
import Cs8 from "./pages/cs8";
import Cs9 from "./pages/cs9";
import Cs10 from "./pages/cs10";
import Cs11 from "./pages/cs11";
import Cs12 from "./pages/cs12";

import "./App.css"

export default function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Landing />
        </Route>
        <Route path="/aboutus" component={Aboutus} />
        <Route path="/healthcare" component={Healthcare} />
        <Route path="/education" component={Education} />
        <Route path="/corporate" component={Corporate} />
        <Route path="/government" component={Government} />
        <Route path="/banking" component={Banking} />
        <Route path="/strategies" component={Strategies} />
        <Route path="/customapp" component={Customapp} />
        <Route path="/integration" component={Integration} />
        <Route path="/casestudies" component={Casestudies} />
        <Route path="/cs1" component={Cs1} />
        <Route path="/cs2" component={Cs2} />
        <Route path="/cs3" component={Cs3} />
        <Route path="/cs4" component={Cs4} />
        <Route path="/cs5" component={Cs5} />
        <Route path="/cs6" component={Cs6} />
        <Route path="/cs7" component={Cs7} />
        <Route path="/cs8" component={Cs8} />
        <Route path="/cs9" component={Cs9} />
        <Route path="/cs10" component={Cs10} />
        <Route path="/cs11" component={Cs11} />
        <Route path="/cs12" component={Cs12} />
      </Switch>
    </Router>
  );
}
