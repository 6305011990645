import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Aboutus() {
  return (
    <LandingLayout>
      <Hero
        title="About Us"
        subtitle="Since our founding in 2000, Genuity has performed every initiative as promised. We’ve never missed a commitment or failed to deliver as promised.  Genuity’s clients range from entrepreneur startup enterprises to fortune 500 corporations and span the healthcare, banking, education and training, government, sports, retail and not-for-profit industries."
        subtitle2="Genuity helps organizations grow, expand and succeed. We accomplish this by providing meaningful solutions to challenging business and technology initiatives. We are recognized for our collaborative working relationships, business knowledge and capability to accomplish challenging initiatives through mobile, server and mainframe technologies."
        subtitle3="“Your success is our business” is a Genuity mission and trademark. Learn more about Genuity by viewing our case studies and examples of our mobile technology products or call us at 941-955-1800."
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
