import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Government() {
  return (
    <LandingLayout>
      <Hero
        title="Government"
        subtitle="Genuity helps city, county, health and educational government entities improve delivery and management of services, integrate decision applications and develop customized mobile and server-based software applications."
        subtitle2="Genuity’s government services range from developing new software applications to automating operational processes and creating systems which help the community access government services through mobile, web-based or touchscreen kiosk applications, to developing software to support and manage government meeting agendas, data records and integrating new technologies."
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
