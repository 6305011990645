import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Strategies() {
  return (
    <LandingLayout>
      <Hero
        title="Innovative Process Improvement & Automation Strategies"
        subtitle="Genuity specializes in “making it happen.” Successful organizations know that change is inevitable, whether it is driven by growth, economic conditions, competition, regulatory compliance or any number of other factors. Genuity helps organizations embrace and benefit from change through meaningful use of technology. When your leaders begin to use phrases such as “I wish we could ….” or “Wouldn’t it be great if …” or “How are we ever going to ….”, there are no magic wands. However a call to Genuity is at your finger tips, 941-955-1800."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
