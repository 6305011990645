import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs9() {
  return (
    <LandingLayout>
      <Hero
        title="Healthcare Marketing: Presentations to GO"
        subtitle="A nationally recognized hospice organization was seeking a better way to manage its liaison presentation of information and communications to families, physicians, hospitals and long term care providers. Liaisons were always on the go and needed to present different types of information to various types of audiences whether it be a family or a health care executive. Additionally, content needed to be regularly updated and distributed to the team of liaisons. Much of the time, liaisons are mobile and do not have access to the internet."
        subtitle2="To tackle this challenge, Genuity recommended PrezentPro, a Genuity iPad content management product which enabled the hospice to create and manage their own branded set of videos, presentations, documents and other content. The content could be directly downloaded to a specific liaison’s iPad for presentation and communication of the hospice services. Families and health care providers could now visualize the hospice facilities and services and receive electronic transmissions of the content for further access and review. Additionally, the liaisons have the capability to provide feedback on new presentations and even update their SalesForce.com account."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
