import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs6() {
  return (
    <LandingLayout>
      <Hero
        title="Restaurant Kiosks: Enhancing the purchase experience"
        subtitle="When a franchised food chain wanted to determine if they could change the customer experience and buying decision through touchscreen ordering, they engaged Genuity.  Genuity jointly worked with the organization to evaluate customer purchasing behavior and decision factors. Genuity then developed a customer touchscreen ordering platform which was pilot tested by customers purchasing products. The pilot program resulted in identifying specific customer purchasing decision factors and demonstrated a 20% increase of sales through touchscreen ordering."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
