import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Customapp() {
  return (
    <LandingLayout>
      <Hero
        title="Custom Application Development"
        subtitle="Genuity develops, integrates and supports custom mainframe, server and mobile software applications. Companies make significant investments in technology to support the changing needs of their business. In many cases, the technology infrastructure only supports 75% to 85% of overall business needs. This is a “technology gap” in which businesses implement controls and processes as workarounds to the gaps. Genuity helps organizations by tailoring technology solutions to maximize support of the business needs. We help organizations fill and bridge the technology gaps to improve operational efficiencies, minimize risks and maximize profit margins. The end result is generally happy employees, customers and clients. After all, organizations would not be successful if their products and services only worked 75% of the time, so why would they be satisfied if their technology infrastructure only supported a portion of their business needs? Explore options to better support your business needs by calling 941-955-1800."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
