import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs2() {
  return (
    <LandingLayout>
      <Hero
        title="Exponential Bank Growth: Reinvention can be the difference between “AWESOME” or “same-old-same-old”"
        subtitle="When a large U.S. financial organization faced the challenge of rapidly completing a series of multiple bank acquisition mergers and loan portfolio conversions within a limited timeframe, the projects seemed overwhelming. Resources were limited and the proven methodologies utilized in prior conversions could not support such a significant increase of mergers across multiple states within the required timeframe."
        subtitle2="Genuity addressed the challenge by re-innovating the data conversion process.  Genuity developed a new platform of specialized data conversion tools and software which reduced required conversion team resources by 70% and cut the conversion timeframes in half. The organization successfully completed their required acquisitions and portfolio conversions and continues to utilize Genuity’s innovative methodology and software platform to support ongoing growth and acquisitions."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
