import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs12() {
  return (
    <LandingLayout>
      <Hero
        title="Leveraging Physician Insights: Helping to make healthcare more affordable"
        subtitle="When a recognized physician shared insights of how prescription costs can be reduced, Genuity took note. The number of Americans without health insurance was on the rise and those with insurance were facing increased premium rates, higher deductibles and greater tier co-pay amounts. Many patients were not taking their medications as prescribed and/or sacrificing other living necessities to pay for their medications. Imagine, if Genuity could change this scenario and help millions of Americans economically and health wise, it would make a meaningful difference within families, communities and nationally."
        subtitle2="This is the mission which drove Genuity to create a series of prescription-cost-saving services. Genuity created an automated software platform for self-insured organizations and a direct on-line service for individuals and families. Genuity also launched a community outreach prescription discount program for uninsured families. All in all, Genuity created services successful in helping thousands of Americans save millions of dollars in prescription costs, in addition to helping communities fund additional health service initiatives."
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
