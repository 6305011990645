import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Healthcare() {
  return (
    <LandingLayout>
      <Hero
        title="Healthcare"
        subtitle="Genuity is the “go to solution provider” for customized healthcare applications. Developing software for mobile, server and mainframe technology, Genuity helps entities improve operational performance, comply with regulatory requirements, manage electronic data and develop intelligent decision systems. Genuity clients include nationally recognized hospitals, healthcare provider networks, physician hospital organizations, community health service organizations and pharmaceutical management companies."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}