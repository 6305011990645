import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs7() {
  return (
    <LandingLayout>
      <Hero
        title="Community Health: Making a meaningful difference"
        subtitle="When a leading community hospital recognized that an increasing number of small businesses within their community did not provide health insurance for their employees, the hospital took action. With the community comprised primarily of small businesses which simply could not afford to offer health coverage, a growing number of uninsured was on the rise. Families without insurance were not scheduling regular checkups or seeking primary physician care for common health issues. The result was an increase in more serious health issues at a higher cost of treatment. The hospital tackled this challenge by creating a unique managed health care insurance program specifically for uninsured businesses within the community. The coverage provided wellness care as well as covering serious conditions and hospitalization.  The lower premium cost (approximately 60% of traditional coverage) enabled small businesses to provide health coverage for their employees."
        subtitle2="Genuity assisted the hospital by developing the specialized software platform to support and manage the entire cycle of health care processing including; employer and employee policy enrollment, electronic medical records management, medical authorization and approvals, electronic physician patient referral management and much more. Genuity also assisted the hospital and marketing agency in creating award-winning marketing and promotion strategy and media advertising and funding options."
        subtitle3="The program has been successful in making quality health care accessible to thousands within the community and viewed as a model for other communities."
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
