import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Education() {
  return (
    <LandingLayout>
      <Hero
        title="Education"
        subtitle="As an early pioneer of online and distance-learning programs, Genuity worked with the Florida Department of Education, University of Florida and Mote Marine Laboratories in 2000 to launch an innovative distance-learning program with middle schools throughout Florida. The successful initiative provided educators with an online curriculum, enabled student interactive studies and facilitated real time interaction between students, educators and researchers."
        subtitle2="Today, Genuity works with an extensive clientele of public, private and corporate educators in developing and integrating mobile technology, developing customized software solutions and automating operational processes."
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
