import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";

export default function Banking() {
  return (
    <LandingLayout>
      <Hero
        title="Banking"
        subtitle="Genuity is a trusted and proven service provider to many of the top 30 U.S. banks.  Genuity’s specialized mobile, server and mainframe technology services help financial organizations with M&A data conversions, customization and support of consumer lending and collection systems, regulatory compliance, integration of new technologies, integration of specialized software applications, IT staff training and mentoring, and much more."
        subtitle2="Whether it is developing a specialized software application or developing and supporting a business critical platform, Genuity has earned a reputation for making a meaningful difference in helping banks grow, expand and re-innovate."
        subtitle3=""
        image=""
        ctaText="Contact Genuity® Today"
        ctaLink="/#contact"
      />
    </LandingLayout>
  );
}
