import React from "react";

import Hero from "../components/sections/Hero";
import LandingLayout from "../components/layouts/LandingLayout";



export default function Cs4() {
  return (
    <LandingLayout>
      <Hero
        title="Trade Show Breakthrough for a Manufacturer: Creating the power to differentiate"
        subtitle="When an international manufacturing entity wanted to stand out and differentiate themselves from all others at a global tradeshow, they engaged their marketing agency to make it happen. The agency turned to Genuity to develop an innovative mobile application enabling the manufacturer and agency to organize and present sales presentations, videos, documents and information directly from iPads and mobile devices. iPads were positioned at the tradeshow for client access to explore and navigate independently and with the manufacturer to personally discuss and present information to each client. The innovative application enabled the agency to brand each of the manufacturer’s divisions and product lines and to directly download and manage the content to specific devices. The agency and manufacturer were able to remotely change and update content in real time and optionally transmit digital content directly from their mobile devices to a prospective customer. The tradeshow was a great success and the manufacturer was awarded “Grand Marketer of the Year” award for their innovative concept. Today, the manufacturer continues to utilize Genuity’s innovative application to drive international sales and marketing initiatives."
        subtitle2=""
        subtitle3=""
        image=""
        ctaText="Read More Case Studies"
        ctaLink="/casestudies"
      />
    </LandingLayout>
  );
}
